import React from 'react';

import {Page, Grid, Input} from '../components';

const NotFoundPage = () => (
  <Page
    title="404"
    description="404"
    keywords={['404', 'error']}
  >
    <Page.Layout>
      <Grid>
        <Grid.ScreenWidth>
          <h1>404 Error</h1>
          <p>The page you reached doesn't exist</p>
        </Grid.ScreenWidth>
      </Grid>
    </Page.Layout>
  </Page>
);

export default NotFoundPage;
